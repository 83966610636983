$side-content-display: 1140px;
$small-screen: 460px;
$medium-screen: 1920px;
$big-screen: 2560px;

.p-20 {
  padding: 20px;
}

.error-message {
  color: var(--ion-color-danger);
  font-size: 12px;
}

.error {
  text-align: center;
}

.logo {
  height: 26px;
  margin-top: 7px;
}

.back-arrow {
  height: 25px;
}

.header_icon {
  width: 25px;
}

.footer_icon {
  width: 35px;
}

.primary-btn {
  border-radius: 7px;
  background-color: var(--ion-color-primary);
  background-image: none;
  box-shadow: none;
  font-size: 13px;
  position: relative;
  color: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  margin: 0;
  --background-activated: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --box-shadow: none;
  text-transform: capitalize;
  height: 50px;
  --padding-start: 0;
  --padding-end: 0;
  z-index: 9;

  ion-title {
    padding: 0 16px 0 16px;
    font-size: 17px;
    letter-spacing: 1px;
    line-height: normal;
  }

  .toolbar-title {
    line-height: normal;
  }
}

.secondary-btn {
  border-radius: 7px;
  border: 1px solid var(--ion-color-primary);
  background-image: none;
  box-shadow: none;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;

  color: var(--ion-color-secondary);
  width: 100%;
  text-align: center;
  bottom: 0;
  --background-activated: var(--ion-color-primary);
  --background: var(--planeed-color-white);
  --background-focused: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --box-shadow: none;
  text-transform: capitalize;
  height: 50px;
  --padding-start: 0;
  --padding-end: 0;
  z-index: 9;

  ion-title {
    padding: 0 16px 0 16px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: normal;
    color: #6e6e6e;
  }

  .toolbar-title {
    line-height: normal;
  }
}

.dark-btn {
  border-radius: 7px;
  background-color: var(--ion-color-secondary);
  background-image: none;
  box-shadow: none;
  font-size: 13px;
  position: relative;
  color: var(--planeed-color-white);
  width: 100%;
  text-align: center;
  margin: 0;
  --background-activated: var(--ion-color-secondary);
  --background: var(--ion-color-secondary);
  --background-focused: var(--ion-color-secondary);
  --background-hover: var(--ion-color-secondary);
  --box-shadow: none;
  text-transform: capitalize;
  height: 50px;
  --padding-start: 0;
  --padding-end: 0;
  z-index: 9;

}

.load-more-btn {
  border-radius: 7px;
  --background: var(--ion-color-secondary);
  color: var(--planeed-color-white);
  padding: 0;
  margin-top: 10px;
}

.read-more, .read-less {
  color: #000;
}

ion-header {
  background: var(--planeed-color-white);
  box-shadow: 0 0 0;
}

.comment-btn {
  --background: none;
  --box-shadow: none;

  --padding-start: 0px;
  --padding-end: 0px;
}

ion-toolbar:last-of-type {
  @media only screen and (max-width: $small-screen) {
    --border-width: 1px !important;
  }
  box-shadow: 0 0 0;
}

ion-card {
  box-shadow: 0 0 0;
}

.header-md::after {
  background-image: none;
}

.back-arrow {
  height: 20px;
  width: 20px;
}

.loading {
  text-align: center;
}

.text-center {
  text-align: center;
}

.comment-input {
  max-height: 200px;
  width: calc(100% - 50px);
}

.comment-datetime {
  color: var(--ion-color-medium-shade);
  font-style: italic;
  font-size: 12px;
}

.searchbar-search-icon.sc-ion-searchbar-ios {
  left: inherit;
  right: 10px;
  display: none;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  padding-inline-start: 10px;
  padding-inline-end: 0;
}

.team-search.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
  border: 1px solid var(--ion-color-secondary);
  background: var(--planeed-color-lightgrey);
  color: var(--ion-color-secondary);
  border-radius: 10px;
}

.sc-ion-searchbar-ios-h {
  --border-radius: 5px;
}

ion-segment {
  background: var(--planeed-color-white);
}

input[type='text'], input[type='password'], textarea {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.meta-image {
  height: 120px;
  width: 100%;
  object-fit: cover;
}

.metainfobox {
  border: 1px #ddd solid;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 5px;
}

.metainfobox ion-col {
  padding: 0;
  margin: 0;
  height: 120px;
}

.metainfobox {
  cursor: pointer;

  .metaDescription {
    color: #888888;
    font-size: 11px;
    line-height: 100%;
  }

  p {
    color: var(--ion-color-text-color);
  }

  h6 {
    color: #888888;
    font-size: 11px;
    font-weight: normal;
    text-decoration: underline;
  }

  img {
    position: relative;
  }
}

.metacontent {
  padding: 10px;
}

#generalModal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 10% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;
}

#datemodal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 20% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;
}

#storydetail {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 10% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;
}

#imageViewModal {
  background: rgba(0, 0, 0, 0.5) !important;
  --backdrop-opacity: 0.3;

  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    --width: 414px; //460 - 10%
    --height: 760px; // * 1.77
  }
  @media only screen and (min-width: $medium-screen) {
    --width: 558px; //620 - 10%
    --height: 1020px; // * 1.77
  }
}

#loginToViewModal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 20% 10%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;

  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    --width: 414px; //460 - 10%
    --height: 760px; // * 1.77
  }
  @media only screen and (min-width: $medium-screen) {
    --width: 558px; //620 - 10%
    --height: 1020px; // * 1.77
  }
}

#momentsWebModal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 10% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;

  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    --width: 414px; //460 - 10%
    --height: 732px; // * 1.77
  }
  @media only screen and (min-width: $medium-screen) {
    --width: 558px; //620 - 10%
    --height: 987px; // * 1.77
  }
}

#team-select-modal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 10% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;

  @media only screen and (max-width: $medium-screen) {
    --width: 414px; //460 - 10%
    --height: 300px; // * 1.77
  }
  @media only screen and (min-width: $medium-screen) and (max-width: $big-screen) {

    --width: 558px; //620 - 10%
    --height: 400px; // * 1.77
  }
  @media only screen and (min-width: $big-screen) {
    --width: 666px; //740 - 10%
    --height: 500px; // * 1.77
  }
}

#small-modal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 10% 5%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;

  @media only screen {
    --width: 414px; //460 - 10%
    --height: 300px; // * 1.77
  }
}


#select-modal {
  background: rgba(0, 0, 0, 0.5) !important;
  padding: 20% 10%;
  --backdrop-opacity: 0.3;
  --border-radius: 20px;

  @media only screen {
    --width: 320px; //460 - 10%
    --height: 290px; // * 1.77
  }
}

textarea:focus-visible {
  outline: 1px solid transparent;
}

.bold-underline-blue {
  color: var(--ion-color-secondary) !important;
  text-decoration: underline !important;
}

input, textarea {
  font-size: 13px !important;
}

.placeholder {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px;
}

.invite-user {
  @media only screen and (max-width: 460px) {
    width: calc(100% - 220px);
  }

  @media only screen and (min-width: 460px) {
    width: 40%;
  }
  border-radius: 7px;
  background-color: var(--ion-color-primary);
  background-image: none;
  box-shadow: none;
  font-size: 13px;
  color: var(--ion-color-secondary);
  text-align: center;
  --background-activated: var(--ion-color-primary);
  --background: var(--ion-color-primary);
  --background-focused: var(--ion-color-primary);
  --background-hover: var(--ion-color-primary);
  --box-shadow: none;
  text-transform: capitalize;
  --padding-start: 0;
  --padding-end: 0;
  z-index: 9;
  margin: 5px 10px 0 auto;
}

.settings-text {
  font-size: 12px;
  margin: 7px 0 0 15px;
  text-decoration: underline;
  color: var(--ion-color-secondary);
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ion-icon {
    margin-right: 4px;
  }
}

.unfollow-btn {
  @media only screen and (max-width: 460px) {
    width: calc(100% - 220px);
  }

  @media only screen and (min-width: 460px) {
    width: 40%;
  }
  border-radius: 7px;
  background-color: var(--ion-color-light);
  background-image: none;
  box-shadow: none;
  font-size: 15px;
  color: var(--ion-color-medium);
  text-align: center;
  --background-activated: var(--ion-color-light);
  --background: var(--ion-color-light);
  --background-focused: var(--ion-color-light);
  --background-hover: var(--ion-color-light);
  --box-shadow: none;
  text-transform: capitalize;
  --padding-start: 0;
  --padding-end: 0;
  z-index: 9;
  margin: 5px 10px 0 auto;
}

.post-content-grey {
  background: var(--planeed-color-lightgrey);
  padding: 10px 0;
  min-height: 300px;
  height: 100%;
}

.post-content-white {
  background-color: var(--planeed-color-white);
  padding: 10px 0;
  min-height: 300px;
}

[type=text]:focus {
  box-shadow: none !important;
}

input:focus {
  outline: none;
}

.arabic {
  direction: rtl
}
