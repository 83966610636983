// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-font-family: 'Poppins', sans-serif;

  --ion-color-primary: #c2d61a;
  --ion-color-primary-rgb: 194, 214, 26;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #abbc17;
  --ion-color-primary-tint: #c8da31;
  --ion-color-secondary: #00373d;
  --ion-color-secondary-rgb: 0, 55, 61;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #003036;
  --ion-color-secondary-tint: #1a4b50;
  --ion-color-tertiary: #6e6e6e;
  --ion-color-tertiary-rgb: 110, 110, 110;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #616161;
  --ion-color-tertiary-tint: #7d7d7d;
  --ion-color-success: #9cc217;
  --ion-color-success-rgb: 156, 194, 23;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #89ab14;
  --ion-color-success-tint: #a6c82e;
  --ion-color-warning: #ff8e14;
  --ion-color-warning-rgb: 255, 142, 20;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e07d12;
  --ion-color-warning-tint: #ff992c;
  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 255, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e00000;
  --ion-color-danger-tint: #ff1a1a;
  --ion-color-medium: #9b9b9b;
  --ion-color-medium-rgb: 155, 155, 155;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #888888;
  --ion-color-medium-tint: #a5a5a5;
  --ion-color-light: #dedede;
  --ion-color-light-rgb: 222, 222, 222;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c3c3c3;
  --ion-color-light-tint: #e1e1e1;

  --ion-color-text-color: #393939;

  --ion-color-tertiary-opacity: #6E6E6E80;
  --planeed-color-lightgrey: #F2F2F2;
  --planeed-color-white: #ffffff;

  --planeed-color-background-light: #f6fad1;

  --swiper-navigation-color: #FFFFFF;

  --swiper-nav-button-background: #ffffff;
  --swiper-nav-button-color: #1a4b50;
}

.md body {
  --ion-background-color: #fff;
}
