/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/style.scss";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/effect-cards';
@import 'swiper/scss/effect-creative';
@import 'swiper/scss/virtual';
@import 'swiper/scss/parallax';

$side-content-display: 1140px;
$small-screen: 460px;
$medium-screen: 1920px;
$big-screen: 2560px;

.swiper .swiper-slide {
  display: block !important;
}

.profile-swiper .swiper-slide {
  height: 100px;
}

.profile-swiper .swiper-slide-active {
  height: 100% !important;
}

.remove-action-point-alert .alert-wrapper {
  padding: 15px 20px;
  border-radius: 25px !important;

  .alert-head h2 {
    font-size: 16px;
    text-align: center;
    color: var(--ion-color-tertiary);
  }

  .alert-message.sc-ion-alert-md {
    max-height: 266px;
    font-size: 12px;
    text-align: center;
    color: var(--ion-color-medium);
  }

  .alert-button-group {
    display: flex;
    justify-content: center;
    width: 100%;

    .alert-button.sc-ion-alert-md {
      padding-inline-start: 20px;
      padding-inline-end: 20px;
    }
  }
}

.confirm-button {
  color: var(--ion-color-secondary);
}

.cancel-button {
  color: orange !important;
  --color: orange !important;

}

.forgot-password {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 100px;
  font-size: 17px;
  line-height: normal;
  color: var(--ion-color-secondary);
  font-weight: 400;
  text-decoration: underline;
  letter-spacing: -0.8px;
}

.rating-popover {
  @media only screen and (min-width: 0) and (max-width: $small-screen) {
    --width: calc(100% - 26px) !important;
  }
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    --width: 434px;
  }
  @media only screen and (min-width: $medium-screen) and (max-width: $big-screen) {
    --width: 596px;
  }
  @media only screen and (min-width: $big-screen) {
    --width: 716px;
  }

  border-style: none;
  background: transparent;
  margin: -2px 0 0 0;

  ion-list {
    padding: 0;
    margin: 0;
  }

  ion-item {
    padding: 0;
    margin: 0;
    border-radius: 30px;
    border: 1px solid var(--ion-color-secondary);
  }
}

.rating-popover-margin {
  @media only screen and (min-width: $small-screen) and (max-width: $medium-screen) {
    margin-left: 58px; //460 / 4 / 2 = 57.625 ~58
  }
  @media only screen and (min-width: $medium-screen) and (max-width: $big-screen) {
    margin-left: 77px;
  }
  @media only screen and (min-width: $big-screen) {
    margin-left: 92px;
  }
}

ion-content {
  --ion-background-color: var(--planeed-color-lightgrey);
}

ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

ion-content::part(scroll) {
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
}

.hover-pointer:hover {
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0f0f0f;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 0 0 #FFFFFF;
}

.space-top {
  width: 100%;

  background-color: var(--planeed-color-lightgrey);
  @media only screen and (max-width: $side-content-display) {
    display: none;
  }

  @media only screen and (max-width: 1920px ){
    height: 20px;
  }
  @media only screen and (min-width: 1920px){
    height: 30px;
  }
}

.ios button.alert-button.popupCancel {
  color: var(--ion-color-danger);
}

.ios button.alert-button.popupSelectable {
  color: var(--ion-color-primary-contrast);
}


